.image-container {
  margin: 50px;
}
.image-container > img {
  width: 256px;
  height: auto;
}
.text-container {
  max-width: 500px;
}

.text-container .title {
  color: var(--light-purple);
  margin: 20px;
}
