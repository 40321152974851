@import url('./styles.css/home-page-styles.css');
@import url('./styles.css/results-page-styles.css');
@import url('./styles.css/detected-results-styles.css');

:root {
  --light-purple: #8c7aff;
}

.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.homepage .button,
.vid_container .button,
.previewPage .button {
  margin: 50px;
  margin-top: 100px;
  padding: 10px 100px;
  background-color: var(--light-purple);
}
.homepage .button {
  margin-top: 50px;
}
.detected-results-wrapper .buttons-wrapper .button {
  background-color: var(--light-purple);
}
.homepage .button:hover,
.vid_container .button:hover,
.previewPage .button:hover {
  background-color: #5e5eaf !important;
}

.resultsPage .button {
  padding: 10px 100px;
  margin: 10px 50px;
  background-color: var(--light-purple);
}

.resultsPage .button:hover {
  background-color: #5e5eaf !important;
}

.error .button {
  padding: 10px 100px;
  margin: 10px 50px;
  background-color: var(--light-purple);
}

.error .button:hover {
  background-color: var(--light-purple) !important;
}
.width-fill {
  width: max-content;
  width: intrinsic;
  width: -moz-max-content;
  width: -webkit-max-content;
}
.height-fill {
  height: max-content;
  height: intrinsic;
  height: -moz-max-content;
  height: -webkit-max-content;
}
