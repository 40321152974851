.resultsPage {
  display: flex;
  flex-direction: column;
}

.results {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 1000px) {
  .results {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.results-paper {
  width: 400px;
  height: 600px;
  margin: 30px;
  padding: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  border-radius: 0 !important;
}

@media only screen and (max-width: 1000px) {
  .results-paper {
    width: 300px;
  }
}

.gradient-bar {
  width: 300px;
}

.arrow {
  height: 25px;
  width: 25px;
  margin: 2px;
}

.results-image-container {
  display: flex;
  flex-direction: column;
  margin: 40px;
  margin-bottom: 0px;
}

.good {
  color: white;
  background-color: rgb(19, 228, 106);
  width: 80px;
  padding: 5px;
  align-self: center;
  font-weight: bold;
}

.fair {
  color: white;
  background-color: rgb(255, 224, 47);
  width: 80px;
  padding: 5px;
  align-self: center;
  font-weight: bold;
}

.poor {
  color: white;
  background-color: red;
  width: 80px;
  padding: 5px;
  align-self: center;
  font-weight: bold;
}


.circle-container {
  margin: 40px 40px 20px 40px;
}
.circle-container > div{
  position: relative;
}
.category-separator{
  width: 85%;
  height: 2px;
  background-color: var(--light-purple);
  border: 0;
}
.circular-gradient-text{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  left: 0;
}

.wait-container {
  height: 100vh;
  width: 100vw;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.4);
}
.wait-container > span > svg {
  color: var(--light-purple);
}
