.detected-results-wrapper {
    width: "100%";
    display: flex;
    flex-direction: column;
    align-items: center
}

.detected-results-wrapper .table-wrapper {
    margin-bottom: 20px
}

.table-wrapper .table-column {
    padding: 0 10px
}

.detected-results-wrapper .buttons-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
}